import React, { forwardRef, memo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BlogSimple from "../../blog/BlogSimple";
import { blogData } from "../../blog/BlogData";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";
import { motion, m } from "framer-motion";
import { motionContainer } from "../../../../utils/GlobalAnimations";
import { Link, useNavigate } from "react-router-dom";

const blogSimpleData = blogData.filter((item) => item.blogType === "simple");

const CardGridWithPage = forwardRef((props, ref) => {
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  const options = parent?.content?.more_options || {};

  return (
    <section
      ref={ref}
      className={`py-[10px] lg:py-[10px] md:py-[10px]  xs:py-[50px] relative bg-[#f1edea] `}
      style={{
        ...cl,
      }}
    >
      <Container fluid>
        <Row className="justify-center text-center font-serif">
          <Col
            xl={6}
            lg={7}
            md={8}
            sm={12}
            className="flex flex-col items-center text-center "
          >
            <h2 className="heading-5 text-[32px] font-bold -tracking-[1px] text-darkgray block w-3/5 mb-0">
              {parent?.subtitle_widget && parent?.subtitle_widget[lang]}
            </h2>
          </Col>
        </Row>
      </Container>

      <section className="relative pt-0 py-[130px] px-[4%] lg:py-[90px] lg:px-[2%] md:py-[75px] sm:py-[50px] xs:px-0">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <motion.div
                className="grid grid-cols-12 grid-flow-row gap-4"
                variants={motionContainer}
                initial="hidden"
                animate="show"
              >
                {items.map((l, _index) =>
                  Boolean(Number(options?.round)) ? (
                    <CardRoundedItem key={_index} item={l} />
                  ) : (
                    <CardItem key={_index} item={l} />
                  )
                )}
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  );
});

const CardItem = forwardRef((props, ref) => {
  const { item } = props;
  const lang = useSelector(selectCurrentLanguage);
  const [loading, setLoading] = useState(true);

  const style = {
    "--overlay-color": "#374162",
  };

  return (
    <div className="col-span-4 xl:col-span-4 lg:col-span-6 md:col-span-6 sm:col-span-12">
      <m.div
        className="blog-Simple xs:block flex flex-col !w-full"
        style={style}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        <img
          className="blog-post-image !w-full"
          src={Utils.formatURL(
            `posts/${item?.post_content_id}/${item?.content?.image_main}?s=md)`
          )}
        />

        {/* <div
          className="blog-post-image !w-full"
          style={{
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage:
              item?.content?.image_main && item?.content?.image_main !== "NULL"
                ? `url(${Utils.formatURL(
                    `posts/${item?.post_content_id}/${item?.content?.image_main}`
                  )}?s=md)`
                : `url(${Utils.placeholderImage()})`,
          }}
        >
          <Link
            aria-label="link"
            to={`${Utils.removeDiacritics(item?.title_lang[lang])}-v${
              item?.post_content_id
            }`}
          ></Link>
        </div> */}
        <div className="post-details !w-full">
          <Link
            aria-label="link"
            to={`${Utils.removeDiacritics(item?.title_lang[lang])}-v${
              item?.post_content_id
            }`}
            className="blog-title"
          >
            {item?.title_lang && item?.title_lang[lang]}
          </Link>
          <p
            className="mb-[25px] xl:mb-[25px] md:mb-[20px] xs:mb-[15px]"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {item?.content?.description_lang &&
              item?.content?.description_lang[lang]}
          </p>
        </div>
      </m.div>
    </div>
  );
});

const CardRoundedItem = forwardRef((props, ref) => {
  const { item } = props;
  const lang = useSelector(selectCurrentLanguage);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const style = {
    "--overlay-color": "#374162",
  };

  return (
    <div className="col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 sm:col-span-12">
      <m.div
        // className="xs:block flex flex-col !w-full"
        className="col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 sm:col-span-12 w-full flex flex-col justify-center items-center space-y-3"
        // style={style}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        <img
          onClick={() => {
            navigate(
              `${Utils.removeDiacritics(item?.title_lang[lang])}-v${
                item?.post_content_id
              }`
            );
          }}
          className="w-[200px] h-[200px] rounded-full hover:cursor-pointer"
          src={
            item?.content?.image_main && item?.content?.image_main !== "NULL"
              ? `${Utils.formatURL(
                  `posts/${item?.post_content_id}/${item?.content?.image_main}`
                )}?s=md`
              : Utils.placeholderImage()
          }
        />

        <div className="!w-full flex flex-col items-center">
          <Link
            aria-label="link"
            to={`${Utils.removeDiacritics(item?.title_lang[lang])}-v${
              item?.post_content_id
            }`}
            className="text-center text-md uppercase font-medium !no-underline !text-inherit"
          >
            {item?.title_lang && item?.title_lang[lang]}
          </Link>
          <p
            className="text-center leading-[20px] text-[14px]"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {item?.content?.description_lang &&
              item?.content?.description_lang[lang]}
          </p>
        </div>
      </m.div>
    </div>
  );
});

export default memo(CardGridWithPage);
