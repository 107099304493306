import React, { Suspense, lazy, useEffect } from "react";
import withAppProviders from "./withAppProviders";
import { useGlobalContext } from "./context/GlobalContext";
import { AnimatePresence } from "framer-motion";
import RoutesConfigs from "./app/configs/RoutesConfigs";
import { useDispatch } from "react-redux";
import {
  constructNavigation,
  getNavigation,
} from "./app/store/slices/navigationSlice";
import { getSessionConfig } from "./app/store/slices/configSlice";

import PageLayout from "./app/layout/PageLayout";
import { constructNavigationFooter } from "./app/store/slices/footerSlice";
import { registerPages } from "./app/pages/PagesRegister";
import { getFooterData } from "./app/store/slices/pageSlice";
import Home from "./app/pages/home/Home";
import PageTemplateI from "./app/pages/standard/PageTemplateI";
import Products from "./app/pages/products/Products";
import { ThemeProvider, createTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

registerPages("layout_home", Home);
registerPages("layout_default_i", PageTemplateI);
registerPages("layout_products", Products);
registerPages("layout_subpage", PageTemplateI);

const App = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the current URL has a trailing slash
    if (location.pathname.endsWith("/") && location.pathname !== "/") {
      // Navigate to the URL without the trailing slash
      navigate(location.pathname.slice(0, -1));
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    dispatch(getSessionConfig())
      .then((res) => {
        if (res.payload) {
          const { payload } = res;
          dispatch(getFooterData());
          dispatch(constructNavigation(payload));
          dispatch(constructNavigationFooter(payload));
        }
        // if(res.error)
        // navigate("/pt/maintenance");
      })
      .catch((res) => {});
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#000",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <AnimatePresence mode={"wait"}>
        <PageLayout />
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default withAppProviders(App);
