import React, { forwardRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage, t } from "../../store/slices/languageSlice";
import { Box, Divider, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import _ from "lodash";
import { Col, Row } from "react-bootstrap";
import Utils from "../../../utils/Utils";
import { Link } from "react-router-dom";
import CustomModal from "../CustomModal";
import Buttons from "../button/Buttons";
import YouTube from "react-youtube";

const TabScrollable = forwardRef((props, ref) => {
  const { item } = props;
  const properties = item?.properties || {};
  const dispatch = useDispatch();
  const lang = useSelector(selectCurrentLanguage);

  const [value, setValue] = React.useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList
          onChange={handleChange}
          aria-label="scrollable tabs properties"
          variant="scrollable"
          allowScrollButtonsMobile
          // indicatorColor={""}
        >
          {!_.isEmpty(properties) &&
            properties
              .filter((f) => !_.isEmpty(f.props_list))
              .map((p, _index) => {
                const { property_tag, name, props_list } = p;
                if (_.isEmpty(props_list)) return null;

                return (
                  <Tab
                    sx={{
                      color: value == _index ? "#000" : "inherit",
                    }}
                    key={_index}
                    label={dispatch(t(`WEBSITE.${property_tag}`))}
                    value={`${_index}`}
                  />
                );
              })}
        </TabList>
      </Box>

      {!_.isEmpty(properties) &&
        properties
          .filter((f) => !_.isEmpty(f.props_list))
          .map((p, _index) => {
            const { property_tag, name, props_list, table_name } = p;
            if (_.isEmpty(props_list)) return null;

            const td = table_name.replace("dt_", "");

            return (
              <TabPanel key={_index} value={`${_index}`}>
                <Row xs={2}>
                  {props_list.map((p, _p) => {
                    const { props_list, name_lang } = p;

                    if (_.isEmpty(props_list)) {
                      const { name_lang, picture, file_download, ...rest } = p;
                      const n =
                        name_lang && name_lang[lang] ? name_lang[lang] : "";
                      let pic = "";
                      let file = "";

                      Object.keys(rest).map((k) => {
                        if (k.includes(`${td}_id`)) {
                          pic = picture
                            ? Utils.formatURL(
                                `data_table/${k.replace(`_id`, "")}/picture/${
                                  rest[k]
                                }/${picture}?s=xs`
                              )
                            : null;
                          file = file_download
                            ? Utils.formatURL(
                                `data_table/${k.replace(
                                  `_id`,
                                  ""
                                )}/file_download/${
                                  rest[k]
                                }/${file_download}?download=${n}`
                              )
                            : null;
                        }
                      });

                      return (
                        <Col
                          key={_p}
                          xl={2}
                          lg={3}
                          md={4}
                          xs={6}
                          className="tab-item"
                        >
                          {file ? (
                            <Link
                              aria-label="link"
                              target={"_blank"}
                              to={file}
                              className="no-underline hover:no-underline"
                            >
                              <div className="flex flex-col py-[20px] md:items-center">
                                <img
                                  height={200}
                                  width={200}
                                  className="rounded-lg"
                                  src={pic || Utils.placeholderImage()}
                                  alt={n}
                                  data-no-retina=""
                                />

                                <div className="w-full">
                                  <span className="font-medium text-sm text-darkgray ">
                                    {n}
                                  </span>
                                </div>
                              </div>
                            </Link>
                          ) : (
                            <div className="flex flex-col py-[20px] md:items-center">
                              <img
                                height={200}
                                width={200}
                                className="rounded-lg"
                                src={pic || Utils.placeholderImage()}
                                alt={n}
                                data-no-retina=""
                              />

                              <div className="w-full">
                                <span className="font-medium text-sm text-darkgray ">
                                  {n}
                                </span>
                              </div>
                            </div>
                          )}
                        </Col>
                      );
                    }

                    const n =
                      name_lang && name_lang[lang] ? name_lang[lang] : "";
                    const name_p =
                      name_lang && name_lang[lang] ? name_lang[lang] : "";
                    const { description_lang, external_url } = p;

                    return (
                      <Box key={_p} className={"w-full"}>
                        <Typography color={"text.secondary"} className="mt-2">
                          {n}
                        </Typography>

                        <Divider
                          className="my-2"
                          sx={{
                            borderColor: "rgba(0, 0, 0, 0.6)",
                          }}
                        />

                        {external_url && external_url[lang] && (
                          <Link
                            aria-label="link"
                            target={"_blank"}
                            to={external_url[lang]}
                            className="no-underline hover:no-underline"
                          >
                            <Typography
                              color={"text.secondary"}
                              className="mt-3 !font-bold !text-sm "
                            >
                              {dispatch(t("WEBSITE.more_details"))}{" "}
                              <i className="feather-external-link"></i>
                            </Typography>
                          </Link>
                        )}
                        <Row xs={2}>
                          {!_.isEmpty(props_list) &&
                            props_list.map((p, _p) => (
                              <TabItem
                                {...p}
                                key={_p}
                                td={td}
                                name_p={name_p}
                              />
                            ))}
                          {/* {!_.isEmpty(props_list) &&
                            props_list.map((p, _p) => {
                              const {
                                name_lang,
                                picture,
                                file_download,
                                video,
                                ...rest
                              } = p;
                              const n =
                                name_lang && name_lang[lang]
                                  ? name_lang[lang]
                                  : "";
                              let v = "";
                              let pic = "";
                              let file = "";

                              Object.keys(rest).map((k) => {
                                if (k.includes(`${td}_id`)) {
                                  v = video
                                    ? Utils.formatURL(
                                        `data_table/${k.replace(
                                          `_id`,
                                          ""
                                        )}/video/${rest[k]}/${video}`
                                      )
                                    : null;
                                  pic = picture
                                    ? Utils.formatURL(
                                        `data_table/${k.replace(
                                          `_id`,
                                          ""
                                        )}/picture/${rest[k]}/${picture}?s=xs`
                                      )
                                    : null;
                                  file = file_download
                                    ? Utils.formatURL(
                                        `data_table/${k.replace(
                                          `_id`,
                                          ""
                                        )}/file_download/${
                                          rest[k]
                                        }/${file_download}?download=${name_p}-${n}`
                                      )
                                    : null;
                                }
                              });

                              return (
                                <Col
                                  key={_p}
                                  xl={2}
                                  lg={3}
                                  md={4}
                                  xs={6}
                                  className="tab-item"
                                >
                                  {v && (
                                    <CustomModal.Wrapper
                                      className="absolute top-0 left-0 w-full h-full flex justify-center items-center"
                                      modalBtn={
                                        <Buttons
                                          type="submit"
                                          className="btn-sonar border-0 !w-[120px] !h-[120px] sm:!w-[80px] sm:!h-[80px]"
                                          themeColor="#232323"
                                          color="#fff"
                                          size="lg"
                                          icon="icon-control-play !ml-[13px] text-[34px] lg:!ml-[10px] sm:text-[28px]"
                                        />
                                      }
                                    >
                                      <div className="w-[1020px] max-w-full relative rounded mx-auto">
                                        <div className="fit-video">
                                          <iframe
                                            width="100%"
                                            height="100%"
                                            className="shadow-[0_0_8px_rgba(0,0,0,0.06)]"
                                            controls
                                            src={v}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                          ></iframe>
                                        </div>
                                      </div>
                                    </CustomModal.Wrapper>
                                  )}
                                  {file ? (
                                    <Link
                                      aria-label="link"
                                      target={"_blank"}
                                      to={file}
                                      className="no-underline hover:no-underline"
                                    >
                                      <div className="flex flex-col py-[20px] md:items-center">
                                        <img
                                          height={200}
                                          width={200}
                                          className="rounded-lg"
                                          src={pic || Utils.placeholderImage()}
                                          alt={n}
                                          data-no-retina=""
                                        />

                                        <div className="w-full">
                                          <span className="font-medium text-sm text-darkgray ">
                                            {n}
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  ) : (
                                    <div className="flex flex-col py-[20px] md:items-center">
                                      <img
                                        height={200}
                                        width={200}
                                        className="rounded-lg"
                                        src={pic || Utils.placeholderImage()}
                                        alt={n}
                                        data-no-retina=""
                                      />

                                      <div className="w-full">
                                        <span className="font-medium text-sm text-darkgray ">
                                          {n}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </Col>
                              );
                            })} */}
                        </Row>
                      </Box>
                    );
                  })}
                </Row>
              </TabPanel>
            );
          })}
    </TabContext>
  );
});

const TabItem = forwardRef((props, ref) => {
  const lang = useSelector(selectCurrentLanguage);

  const {
    name_lang,
    picture,
    file_download,
    video,
    video_url,
    td,
    name_p,
    ...rest
  } = props;
  const n = name_lang && name_lang[lang] ? name_lang[lang] : "";
  let v = "";
  let yt = "";
  let pic = "";
  let file = "";

  Object.keys(rest).map((k) => {
    if (k.includes(`${td}_id`)) {
      v = video
        ? Utils.formatURL(
            `data_table/${k.replace(`_id`, "")}/video/${rest[k]}/${video}`
          )
        : null;
      yt = video_url ? Utils.getYoutubeVideoId(video_url) : null;
      pic = picture
        ? Utils.formatURL(
            `data_table/${k.replace(`_id`, "")}/picture/${
              rest[k]
            }/${picture}?s=xs`
          )
        : null;
      file = file_download
        ? Utils.formatURL(
            `data_table/${k.replace(`_id`, "")}/file_download/${
              rest[k]
            }/${file_download}?download=${name_p}-${n}`
          )
        : null;
    }
  });

  return (
    <Col xl={2} lg={3} md={4} xs={6} className="tab-item">
      {/* {v && (
        <CustomModal.Wrapper
          className="absolute top-0 left-0 w-full flex justify-center items-center"
          modalBtn={
            <IconButton>
              <i className="icon-control-play text-white text-[30px]" />
            </IconButton>
          }
        >
          <div className="w-[1020px] max-w-full relative rounded mx-auto">
            <div className="fit-video">
              <iframe
                width="100%"
                height="100%"
                className="shadow-[0_0_8px_rgba(0,0,0,0.06)]"
                controls
                src={v}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </CustomModal.Wrapper>
      )} */}
      {file ? (
        <Link
          aria-label="link"
          target={"_blank"}
          to={file}
          className="no-underline hover:no-underline"
        >
          <div className="flex flex-col py-[20px] md:items-center relative">
            {(v || yt) && (
              <CustomModal.Wrapper
                className="absolute top-0 left-0 w-full h-full -mt-[10px] flex justify-center items-center"
                modalBtn={
                  <IconButton>
                    <i className="icon-control-play text-white text-[30px]" />
                  </IconButton>
                }
              >
                <div className="w-[1020px] max-w-full relative rounded mx-auto">
                  <div className="fit-video">
                    {v && (
                      <iframe
                        width="100%"
                        height="100%"
                        className="shadow-[0_0_8px_rgba(0,0,0,0.06)]"
                        controls
                        src={v}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    )}
                    {yt && (
                      <YouTube
                        videoId={yt}
                        opts={{
                          height: "100%",
                          width: "100%",
                          playerVars: {
                            autoplay: 1,
                          },
                        }}
                      />
                    )}
                  </div>
                </div>
              </CustomModal.Wrapper>
            )}
            <img
              height={200}
              width={200}
              className="rounded-lg"
              src={pic || Utils.placeholderImage()}
              alt={n}
              data-no-retina=""
            />

            <div className="w-full">
              <span className="font-medium text-sm text-darkgray ">{n}</span>
            </div>
          </div>
        </Link>
      ) : (
        <div className="flex flex-col py-[20px] md:items-center relative">
          {(v || yt) && (
            <CustomModal.Wrapper
              className="absolute top-0 left-0 w-full h-full -mt-[10px] flex justify-center items-center"
              modalBtn={
                <IconButton>
                  <i className="icon-control-play text-white text-[30px]" />
                </IconButton>
              }
            >
              <div className="w-[1020px] max-w-full relative rounded mx-auto">
                <div className="fit-video">
                  {v && (
                    <iframe
                      width="100%"
                      height="100%"
                      className="shadow-[0_0_8px_rgba(0,0,0,0.06)]"
                      controls
                      src={v}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                  {yt && (
                    <YouTube
                      videoId={yt}
                      opts={{
                        height: "100%",
                        width: "100%",
                        playerVars: {
                          autoplay: 1,
                        },
                      }}
                    />
                  )}
                </div>
              </div>
            </CustomModal.Wrapper>
          )}
          <img
            height={200}
            width={200}
            className="rounded-lg"
            src={pic || Utils.placeholderImage()}
            alt={n}
            data-no-retina=""
          />

          <div className="w-full">
            <span className="font-medium text-sm text-darkgray ">{n}</span>
          </div>
        </div>
      )}
    </Col>
  );
});

export default memo(TabScrollable);
