import React, { memo, useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { m, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../store/slices/languageSlice";
import Utils from "../../../utils/Utils";

const itemMotion = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 },
};

const ItemSimple = (props) => {
  const { pathname } = useLocation();
  const { item } = props;
  const params = useParams();
  const lang = useSelector(selectCurrentLanguage);
  const [loading, setLoading] = useState(true);

  const style = {
    "--overlay-color": "#374162",
  };

  return (
    <div className="col-span-4 xl:col-span-4 lg:col-span-6 md:col-span-6 sm:col-span-12 grid-item">
      <m.div
        className="blog-Simple xs:block flex flex-col !w-full"
        style={style}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        <div
          className="blog-post-image h-[250px] !w-full"
          style={{
            backgroundImage: `url(${Utils.formatURL(
              `posts/${item?.post_content_id}/${item?.content?.image_main}`
            )}?s=md)`,
          }}
        >
          <Link
            aria-label="link"
            to={item?.content?.link && item?.content?.link[lang]}
          ></Link>
        </div>
        <div className="post-details !w-full">
          <Link
            aria-label="link"
            to={item?.content?.link && item?.content?.link[lang]}
            className="blog-title"
          >
            {item?.title_lang && item?.title_lang[lang]}
          </Link>
          <p className="mb-[25px] xl:mb-[25px] md:mb-[20px] xs:mb-[15px]">
            {item?.content?.description_lang &&
              item?.content?.description_lang[lang]}
          </p>
        </div>
      </m.div>
    </div>
  );
};

export default memo(ItemSimple);
