import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fetch from "../../../context/AxiosContext";
import _ from "lodash";
import Utils from "../../../utils/Utils";
import { selectCurrentLanguage } from "./languageSlice";

export const getSessionConfig = createAsyncThunk(
  "config/getSessionConfig",
  async (__, { dispatch, getState }) => {
    try {
      const lang = selectCurrentLanguage(getState());
      const res = await fetch.get(`/site/public?lang=${lang}`);

      if (_.isEmpty(res.result)) throw new Error("maintenance");

      const { corporate, lang_list } = res.result;
      if (_.isEmpty(lang_list)) throw new Error("maintenance");

      if (!lang_list.some((l) => l.code == lang))
        return (window.location.href = "/pt");

      if (!_.isEmpty(corporate)) {
        const { favicon, name, logos } = corporate;

        if (_.isEmpty(logos)) return res.result;

        const { dark_theme, light_theme } = logos;

        document.title = name;

        document.querySelector("link[rel='shortcut icon']").href =
          Utils.formatURL(`assets/favicon/${favicon}`);

        dispatch(
          setLightLogos({
            vertical_logo: Utils.formatURL(
              `assets/logos/${light_theme.vertical_logo}`
            ),
            horizontal_logo: Utils.formatURL(
              `assets/logos/${light_theme.horizontal_logo}`
            ),
          })
        );

        dispatch(
          setDarkLogos({
            vertical_logo: Utils.formatURL(
              `assets/logos/${dark_theme.vertical_logo}`
            ),
            horizontal_logo: Utils.formatURL(
              `assets/logos/${dark_theme.horizontal_logo}`
            ),
          })
        );
      }

      return res.result;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
);

export const setPageTitle = createAsyncThunk(
  "config/setPageTitle",
  async (title, { dispatch, getState }) => {
    try {
      const name = selectTitle(getState());

      document.title = `${title} - ${name}`;

      document
        .querySelector('meta[property="og:site_name"]')
        .setAttribute("content", `${title} - ${name}`);
    } catch (error) {}
  }
);

export const setMetas = createAsyncThunk(
  "config/setMetas",
  async (data, { dispatch, getState }) => {
    try {
      const { title, description, url, image, image_alt, type } = data;

      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", description);

      document
        .querySelector('meta[property="og:title"]')
        .setAttribute("content", title);
      document
        .querySelector('meta[property="og:url"]')
        .setAttribute("content", url);
      document
        .querySelector('meta[property="og:description"]')
        .setAttribute("content", description);
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute("content", image);
      document
        .querySelector('meta[property="og:image:alt"]')
        .setAttribute("content", image_alt);
      document
        .querySelector('meta[property="og:type"]')
        .setAttribute("content", type);
    } catch (error) {}
  }
);

const configSlice = createSlice({
  name: "config",
  initialState: {
    title: "",
    dark_theme: {},
    light_theme: {},
    contries: [],
    status: "pending",
  },
  reducers: {
    setDarkLogos: (state, action) => {
      state.dark_theme = { ...action.payload };
    },
    setLightLogos: (state, action) => {
      state.light_theme = { ...action.payload };
    },
  },
  extraReducers: (b) => {
    b.addCase(getSessionConfig.rejected, (state, action) => {
      state.status = "rejected";
    }).addCase(getSessionConfig.fulfilled, (state, action) => {
      const {
        countries_list,
        corporate: { name },
      } = action.payload;

      const items = countries_list.map((c) => {
        const { code, name } = c;

        return {
          ...c,
          value: code,
          label: name,
        };
      });
      state.contries = items;

      state.title = name;
    });
  },
});

export const { setDarkLogos, setLightLogos } = configSlice.actions;

export const selectLogos = ({ config }) => config;
export const selectCountries = ({ config }) => config.contries;
export const selectTitle = ({ config }) => config.title;
export const selectStatus = ({ config }) => config.status;

export default configSlice.reducer;
